// Global namespace

function testIfMobile() {
	// Test for mobile with media queries and touch events
	Vars.isMobile = Modernizr.mq('(max-width: 767px)') && Modernizr.touchevents;
	var action = Vars.isMobile ? 'addClass' : 'removeClass';
	$html[action]('is-mobile');
}

function testIfTablet() {
	// Test for tablet with media queries and touch events
	Vars.isTablet = Modernizr.mq('(max-width: 1024px) and (min-width: 768px)') && Modernizr.touchevents;
	var action = Vars.isTablet ? 'addClass' : 'removeClass';
	$html[action]('is-tablet');
}

function testForDevices() {
	testIfMobile();
	testIfTablet();
}

var Utils = Utils || {};

(function() {
	'use strict';

	// Add tests to Modernizr
	Utils.detects = {
		// Test for mac
		'is-mac': function() {
			return navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC';
		},
		'firefox': function() {
			return navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
		},
		'chrome': function() {
			return navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;
		}
	};

	Modernizr.addTest(Utils.detects);



	Utils.transEndEventNames = {
		'WebkitTransition' : 'webkitTransitionEnd',
		'MozTransition'    : 'transitionend',
		'transition'       : 'transitionend'
	};

	Utils.transitionEnd = Utils.transEndEventNames[ Modernizr.prefixed('transition') ];



	/**
	 * Check if a method exist and launch it
	 * @param  {string}   method Method called
	 * @param  {string}   page   Global object
	 * @return {function}
	 */
	Utils.hasMethod = function(page, method) {

		// Console.log() styles
		var styles = {
			msg: 'color: #00009f;',
			error: 'color: #fd6b00;'
		};

		// Special method for the homepage
		page = page.length ? Utils.camelCase(page) : 'home';
		method = method.length ? Utils.camelCase(method) : 'init';
		var Page = Pages[page];

		try {
			throw new Page();
		} catch (e) {
			console.log('%cPage: Pages.' + page, styles.msg);
			if (e instanceof Error) console.log('%c' + e, styles.error);
			var Method = e[method];
			try {
				console.log('%cMethod: Pages.' + page + '.' + method, styles.msg);
				throw new Method();
			} catch (i) {
				if (i instanceof Error) console.log('%c' + i, styles.error);
				return i;
			}
		}
	};




	/**
	 * Convert a kebab-case string into a camelCase one
	 * @param  {string} string The string to convert
	 * @return {string}        The camelCased string
	 */
	Utils.camelCase = function(string) {

		return string.toLowerCase().replace(/-(.)/g, function(match, group) {
			return group.toUpperCase();
		});
	};


	/**
	 * Returns a function, that, as long as it continues to be invoked, will not
	 * be triggered. The function will be called after it stops being called for
	 * N milliseconds. If `immediate` is passed, trigger the function on the
	 * leading edge, instead of the trailing.
	 * @param  {number}    wait       Timer
	 * @param  {boolean}   immediate  Launch the function immediately
	 * @param  {function}  func       The function that needs debounce
	 * @return {function}             A function to bind to the event debounced
	 */
	Utils.debounce = function(wait, immediate, func) {

		var timeout;

		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};




	/**
	 * Animate scroll for anchor links
	 * @return {void}
	 */
	Utils.smoothScroll = function() {

		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var $this = $(this);
			var duration = $this.data('duration') || 1;
			var $target = $(this.hash);
			if ($target.length) {
				TweenLite.to(window, duration, { scrollTo: { y: $target.offset().top }, ease: Expo.easeInOut });
				return false;
			}
		}
	};



	/**
	 * Create an ending event for the event triggered
	 * @param  {object} e The triggered event's object
	 * @return {undefined}
	 */
	Utils.addEndEvent = Utils.debounce(200, false, function(e) {
		$(this).trigger(e.type + 'End');
	});



	/**
	 * Convert degrees to rad
	 * @param  {integer} degrees The angle to convert
	 * @return {integer}         The converted angle in rad
	 */
	Utils.degToRad = function(degrees) {
		return degrees * Math.PI / 180;
	};



	/**
	 * Get a random integer in the given interval
	 * @param  {integer} min The interval's minimum
	 * @param  {integer} max The interval's maximum
	 * @return {integer}     A random integer in the given interval
	 */
	Utils.getRandomInt = function(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	};



	/**
	 * Compute all colors for a gradient
	 * @param  {object} startColor  An object containing the red, green and blue channels of the first color
	 * @param  {object} endColor    An object containing the red, green and blue channels of the last color
	 * @param  {number} percent     The position of the color to compute, in percentage
	 * @return {object}             An object containing the red, green, blue and hexadecimal info of the computed color
	 */
	Utils.makeGradientColor = function(startColor, endColor, percent) {

		var newColor = {};

		function makeChannel(a, b) {
			return (a + Math.round((b-a)*(percent/100)));
		}

		function makeColorPiece(num) {
			num = Math.min(num, 255);   // not more than 255
			num = Math.max(num, 0);     // not less than 0
			var str = num.toString(16);
			if (str.length < 2) {
				str = '0' + str;
			}
			return str;
		}

		newColor.r = makeChannel(startColor.r, endColor.r);
		newColor.g = makeChannel(startColor.g, endColor.g);
		newColor.b = makeChannel(startColor.b, endColor.b);
		newColor.hex = '#' + makeColorPiece(newColor.r) + makeColorPiece(newColor.g) + makeColorPiece(newColor.b);
		return newColor;
	};


	/**
	 * Set gradient on child elements
	 * @param {string} container The container of the elements to set the color to
	 * @param {object} options   The options of the gradient
	 */
	Utils.setGradient = function(container, options) {

		var $containers = $document.find(container);

		$containers.each(function(i, el) {
			var $items = $containers.eq(i).find(options.selector);
			$items.each(function(i, el) {
				var $this = $(this);
				var percent = i * 100 / $items.length;
				var color = Utils.makeGradientColor(options.startColor, options.endColor, percent);
				$this.css({ color: color.hex });
			});
		});
	};



	/**
	 * Util to print some content
	 * @return {undefined}
	 */
	Utils.print = function(e) {
		console.log('print');
		e.preventDefault();

		// If not on Chrome, print traditionnally
		if (!Modernizr.chrome) {
			window.print();
			return;
		}

		// Styles
		var $stylesheet = $document.find('link[rel="stylesheet"][media="print"]');

		// Content
		var selector = $(this).data('container');
		var content = $(selector).html();


		var uri = [
			'data:text/html;charset=UTF-8, ',
			'<head>',
				'<title>Imprimer cette page</title>',
				'<link rel="stylesheet" href="' + $stylesheet.attr('href') + '">',
				'<script>',
					'window.onload = function() { window.print(); };',
				'</script>',
			'</head>',
			'<body>',
				content,
			'</body>'
		];

		var screenWidth = screen.availWidth || screen.width;
		var screenHeight = screen.availHeight || screen.height;

		var height = screenHeight*0.8;
		var width = height/1.3;

		var popup = window.open(uri.join(''), 'coucou', 'location=no,scrollbars=yes,width=' + width + ',height=' + height + ',top='+ (screenHeight/2-height/2) +',left='+ (screenWidth/2-width/2));
	};

}());
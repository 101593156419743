/*
##     ##     ###     ########   
##     ##    ## ##    ##     ##  
##     ##   ##   ##   ##     ##  
##     ##  ##     ##  ########   
 ##   ##   #########  ##   ##    
  ## ##    ##     ##  ##    ##   
   ###     ##     ##  ##     ##  
*/
var Vars = Vars || {};
Vars.pageIsLoaded = false;
TweenLite.defaultEase = Expo.easeOut;
var $document = $(document);
var $window = $(window);
var $html = $('html');
var $body = $('body');
var $htmlBody = $html.add($body);
var $loader  = $('#loader');
var $spinner = $('#spinner rect');
var pageHeight = $(window).innerHeight();

String.prototype.decodeHTML = function() {
	return $("<div>", {html: "" + this}).html();
};

var scroll = true;

/**
 * Video(s) album
 */
var videoIsPlaying = function() {
	return function() {
		$(this).addClass('is-playing');
	};
};


var redirectTo = function(url) {
	if(url === location.href) {
		return;
	}

	tl = new TimelineMax();

	tl.add([
		TweenMax.fromTo( $spinner, 0.5, { top: pageHeight }, { top: 0 } ),
		TweenMax.fromTo( $loader, 0.5, { opacity: 0, top: -pageHeight }, { opacity: 1, top: 0, onComplete: function() {
			
			window.location = url;
		} } )
	]);
};

function resize(e) {
	testForDevices();
	var pageHeight = $window.innerHeight();
}

/*
 #######   ##    ##   
##     ##  ###   ##   
##     ##  ####  ##   
##     ##  ## ## ##   
##     ##  ##  ####   
##     ##  ##   ###   
 #######   ##    ##   
*/


$(window).on({
	resize: Utils.addEndEvent,
	resizeEnd: resize
});

/*
##          #######      ###     ########   
##         ##     ##    ## ##    ##     ##  
##         ##     ##   ##   ##   ##     ##  
##         ##     ##  ##     ##  ##     ##  
##         ##     ##  #########  ##     ##  
##         ##     ##  ##     ##  ##     ##  
########    #######   ##     ##  ########   
*/
$(window).load(function() {
	// setTimeout(function() {
	// 	    $('.loader').fadeOut();
	// }, 2000);
	TweenMax.fromTo( $loader, 0.5, { opacity: 1, top: 0 }, { opacity: 0, top: pageHeight, delay: 0.5 } );
	//TweenMax.fromTo( $spinner, 0.5, { opacity: 1 }, { opacity: 0, delay: 0.6 } );
});

/*
########   ##     ##  ##    ##    ######    ########   ####   #######   ##    ##   
##         ##     ##  ###   ##   ##    ##      ##       ##   ##     ##  ###   ##   
##         ##     ##  ####  ##   ##            ##       ##   ##     ##  ####  ##   
######     ##     ##  ## ## ##   ##            ##       ##   ##     ##  ## ## ##   
##         ##     ##  ##  ####   ##            ##       ##   ##     ##  ##  ####   
##         ##     ##  ##   ###   ##    ##      ##       ##   ##     ##  ##   ###   
##          #######   ##    ##    ######       ##      ####   #######   ##    ##   
*/
/**
 * Google map
 */
function initMap() {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 13,
        scrollwheel: false,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(45.76656372172877, 4.865656298884928), // 60 rue d'Inkermann - 69006 LYON

        // How you would like to style the map. 
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"},{"color":"#f4f6f7"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"off"},{"color":"#dee2e4"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"geometry","stylers":[{"lightness":-25},{"saturation":-97},{"color":"#a4afb6"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]}]
    };

    // Get the HTML DOM element that will contain your map 
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    var image = new google.maps.MarkerImage('/images/pointer@2x.png', null, null, null, new google.maps.Size(25,35));

    // Let's also add a marker while we're at it
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(45.76656372172877, 4.865656298884928),
        map: map,
        icon: image
    });
}


/*
########    #######   ##     ##  ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###  ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####  ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##  ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##  ##     ##  ########   ##     ##  ########      ##      
*/

	$(function () {
		$('.popup-modal').magnificPopup({
			type: 'inline',
			midClick: true,
			mainClass: 'mfp-fade'
		});



		$(document).on('click', '.menu--tarif', function (e) {
			e.preventDefault();
			$.magnificPopup.close();
		});
	});

	$(document).ready(function() {
		$('.zoom-gallery').magnificPopup({
			delegate: 'a',
			type: 'image',
			closeOnContentClick: true,
			closeBtnInside: false,
			mainClass: 'mfp-with-zoom mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function(item) {
					return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
				}
			},
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true,
				duration: 300, // don't foget to change the duration also in CSS
				opener: function(element) {
					return element.find('img');
				}
			}
			
		});
	});


$(function () {


$(document).ready(function() {

	$('.equipe-studio.1, .listening-link-inner--content.1, .section--tarif:first-child, .link-image-album, .contact--container-top, .contact--container contact--container__form, .section--ref').css({'opacity':'0', 'visibility':'visible'});
 	$('.equipe-studio.1, .listening-link-inner--content.1, .section--tarif:first-child, .link-image-album, .contact--container-top, .contact--container contact--container__form, .section--ref').delay(500).animate({'opacity':'1'}, 800);

    
    /* Every time the window is scrolled ... */
    $(window).scroll( function(){
    
        /* Check the location of each desired element */
        $('.specialistes-studio, .formules-studio, .equipe-studio, .listening-link-inner--content, .section--tarif').each( function(i){
            
            var bottom_of_object = $(this).position().top;
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            
            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object ){
                
                $(this).animate({'opacity':'1'},1500);
                    
            }
            
        }); 
    
    });
    
});

	$(window).on( 'load', function(){
		$('.gallery-isotope').isotope({
		  	itemSelector: '.item',
		  	layoutMode: 'masonry',
		   	filter: '*',
		     	animationOptions: {
		     		duration: 500,
		     		easing: 'linear',
		     		queue: false
		     	}
		});
	}); 
	
	
	$(window).scrollTop(0);

	/**
	 * Header style
	 */

	$(window).scroll(function () {
		if ($(window).scrollTop() === 0) {
			$('html').addClass('ontop');
		}
	});

	$('body').waypoint(function(direction){
		if (direction === 'down') {
			$('html').removeClass('ontop');
		} else {
			$('html').addClass('ontop');
		}
	}, {offset: '-'+'80px'+'px' });


	/**
	 * FastClick
	 */
	FastClick.attach(document.body);

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$("document").on("click",".browserdetect__close",function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();


	/**
	 * Toggle menu
	 */
	$(document).on("click", ".nav-toggle", function(e) {
		e.preventDefault();
		$(".navigation").toggleClass("open");
		$(".lines-button").toggleClass("close");
	});

	/**
	 * Slider home
	 */
	// $('.slide').waypoint(function (direction) {

	// 	dataslide = $(this.element).data('slide');

	// 	if(scroll){
	// 		$('body.home .menu a').removeClass('current');

	// 		if (direction === 'down') {
	// 			$('body.home .menu a[data-slide="' + dataslide + '"]').addClass('current');
	// 		} else {
	// 			dataslide -= 1;
	// 			$('body.home .menu a[data-slide="' + dataslide + '"]').addClass('current');
	// 		}
	// 	}

	// }, { offset:'100px' });


	$('#fnombre').click(function() {
    	$(".nbre-appro").css({'opacity':'1', 'display':'block'});
	});


	/**
	 * Scroll to
	 */
	var hash = window.location.hash;
	if( hash ) {
		setTimeout(function() {
			$(window).scrollTop(0);
		}, 1);
		setTimeout(function() {
		    $(".menu a[data-anchor='"+hash+"']").click();
		}, 800);
	}

	$("body.home .menu a[data-anchor]").on('click', function(e) {
		e.preventDefault();
		//$('.loader').hide();
		anchor    = $(this).attr('data-anchor');
		anchorTop = $(anchor).offset().top - 80;
		scroll = false;
		$(".menu a[data-anchor]").removeClass('current');
		$(this).addClass('current');
		$(".navigation").removeClass("open");
		$(".lines-button").removeClass("close");
		$('body,html').animate({scrollTop: anchorTop}, 800, function(){
			scroll = true;
		});
	});

	$('.input-file-trigger').click(function() {
		$(this).prev('.input-file').focus();
		return false;
	});

	$('.input-file').change(function() {
		fileReturn = $(this).val();
		$(this).parent().find('.file-return').empty().append(fileReturn);
	});


	/**
	 * Slider albums [Owl Carousel]
	 */
	$(".owl-carousel--album").owlCarousel({
		items: 1,
		loop: true,
		nav: true,
		navText: [,],
		animateIn: 'fadeInRight',
		animateOut: 'fadeOutRight'
	});

	/**
	 * Slider presse [Owl Carousel]
	 */
	$(".owl-carousel--press").owlCarousel({
		loop: true,
		nav: true,
		navText: [,],
		margin: -1,
		smartSpeed: 500,
		// slideBy: 3,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		responsive: {
			0 : {
				items: 1,
			},
			480 : {
				items: 2,
			},
			768 : {
				items: 3,
			}
		}
	});

	/**
	 * Slider presse [Owl Carousel]
	 */
	$("#lightgallery").lightGallery({
		selector: '.owl-item > a',
		counter: false
	});

	/**
	 * Slider photos détail album [Owl Carousel]
	 */
	$('.owl-carousel--detail').imagesLoaded( function() {
		$(".owl-carousel--detail").owlCarousel({
			loop: true,
		    margin: 0,
		    smartSpeed: 800,
		    autoWidth: true,
		    center: true,

		    nav: true,
		    navText: [,],

		    responsive: {
		        0: {
		            items: 1,
		            stagePadding: 40
		        },
		        768: {
		            items: 2,
		            stagePadding: 100
		        },
		        1200: {
		            items: 3,
		            stagePadding: 120
		        }
		    }
		});
	});

	/**
	 * Slider tarifs [Owl Carousel]
	 */
	$(".owl-carousel--tarif").owlCarousel({
		items: 1,
		loop: true,
		nav: true,
		navText: [,],
		animateIn: 'fadeInRight',
		animateOut: 'fadeOutRight'
	});


	/**
	 * Video album
	 */
	var $fade_in_videos = $('.fade-in-video');
	for( i=0; i < $fade_in_videos.length; i++ ) {
		$fade_in_videos[i].addEventListener("playing", videoIsPlaying());
	}


	// Gestion des clics sur les liens et de la redirection
	$(document).on("click", "[data-link]", function() {
		redirectTo($(this).attr("data-link"));
	});

	$(document).on("click","a:not([data-anchor],.no-ajax)",function(e) {
		e.preventDefault();
		redirectTo(this.href);
	});

	$(document).on("click", "[data-link] a", function(e) {
		e.stopPropagation();
	});

	/**
	 * Google Map : init
	 */
	if ($('#map').length > 0) {
		initMap();
	}


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.closest('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.closest('li').removeClass('error');
		}
	});

	$("#fContact").submit(function(){		
		// if($("#fContact").validationEngine('validate')){
		// 	var data = $(this).serialize() + '&act=envoi';
		// 	var div  = $(".formReturn").empty().html('<div class="callback"><span>envoi en cours...</span></div>');
		// 	var url = $(this).attr("action");

		// 	$.ajax({
		// 		type    : "POST",
		// 		data    : data,
		// 		url     : url,
		// 		success : function(retour) {
		// 			div.empty().append(retour);
		// 		}
		// 	});
		// }
		// return false;

		if($("#fContact").validationEngine('validate')){
			var data  = $(this).serialize() + '&act=envoi';
			var url   = $(this).attr('action');
			
			$(this).addClass('sending');
			var thank = $(this).find(".form__message").find('.retour').html('Envoi du message en cours...');
			// var thank = $(this).parent().find(".retour").html('Envoi du message en cours...');
			

			$.ajaxFileUpload({
				data    : data,
				url     : url+'?'+data,
				secureuri:      false,
				fileElementId1: 'fphotos',
				dataType:       'text',
				success : function(retour) {
					thank.html(retour);
				}
			});
		}
		return false;
	});

	/*
	########    #######   ########   ##     ##  ##     ##  ##         ########   
	##         ##     ##  ##     ##  ###   ###  ##     ##  ##         ##         
	##         ##     ##  ##     ##  #### ####  ##     ##  ##         ##         
	######     ##     ##  ########   ## ### ##  ##     ##  ##         ######     
	##         ##     ##  ##   ##    ##     ##  ##     ##  ##         ##         
	##         ##     ##  ##    ##   ##     ##  ##     ##  ##         ##         
	##          #######   ##     ##  ##     ##   #######   ########   ########   
	*/

	$('.js-formule').on('click', function(e){
		e.preventDefault();
		var div = $(this).next('.price--detail-content');
		div.addClass('price--detail-content--show');
		$('body').addClass('noscroll');
		setTimeout(function(){ 
			div.find(".price--detail-contenu").mCustomScrollbar({
				scrollbarPosition: "outside"
			});
		}, 1300);
	});

	$('.cd-close--tarif').on('click', function(e){
		e.preventDefault();
		var div = $(this).closest('.price--detail-content');
		div.removeClass('price--detail-content--show');
		div.find(".price--detail-contenu").mCustomScrollbar("destroy");
		$('body').removeClass('noscroll');
	});


	/*
	 #######   ##     ##  ########    ######    ########   ####   #######   ##    ##   
	##     ##  ##     ##  ##         ##    ##      ##       ##   ##     ##  ###   ##   
	##     ##  ##     ##  ##         ##            ##       ##   ##     ##  ####  ##   
	##     ##  ##     ##  ######      ######       ##       ##   ##     ##  ## ## ##   
	##  ## ##  ##     ##  ##               ##      ##       ##   ##     ##  ##  ####   
	##    ##   ##     ##  ##         ##    ##      ##       ##   ##     ##  ##   ###   
	 ##### ##   #######   ########    ######       ##      ####   #######   ##    ##   
	*/

	$('.request-categorie li').on('click', function(){
		$('.request-categorie li').removeClass('active');
		$(this).addClass('active');

		var id  = $(this).data('id');
		var div = $('.request-question-list[data-id="'+id+'"]');

		$('.request-answer').slideUp(0);	

		$('.request-question-list').removeClass('active');
		div.addClass('active');

		$(window).scrollTop( $('.request-answers').offset().top - $('.header-top').height() , 1000 );

	});

	$('.request-header').on('click', function() {

		if ( $(this).hasClass('opened') ) {
			$(this).removeClass('opened');
		} else {
			$(this).addClass('opened');
		}
		$(this).next('.request-answer').slideToggle(300, function(){
			// $('.request-answers').css('height', $('.request-question-list.active').height() );	
			
		});
	});

	// if ($('.request-header').on('click')){
	// 	$('.icon-plus').css({'transform':'rotate(45deg)'});
	// }
	// else {
	// 	$('.icon-plus').css({'transform':'rotate(0deg)'});
	// };
});



